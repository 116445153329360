import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'public',
		pathMatch: 'full',
	},
	{
		path: 'public',
		loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
	},
	,
	{
		path: 'private',
		loadChildren: () => import('./private/private.module').then((m) => m.PrivateModule),
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
