import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { ILogoPayload } from 'src/app/shared/interfaces/chat.interface';

@Component({
	selector: 'app-popup-button',
	templateUrl: './popup-button.component.html',
	styleUrls: ['./popup-button.component.scss'],
})
export class PopupButtonComponent implements OnInit {
	chatShow: boolean = true;
	companyColor: any = '#0DB8EE';
	openButtonSrc = 'assets/icons/chat/open-button.svg';
	@Input() chatBotlogoData: ILogoPayload;

	constructor() {}

	ngOnInit() {
		this.svgColor();
	}

	public svgColor(): void {
		const style = document.createElement('style');
		style.type = 'text/css';
		style.id = 'svgColorStyle';
		style.innerHTML = `
      .primary-fill { fill: ${this.companyColor};}
      .primary-stroke{ stroke: ${this.companyColor}; }
      .lightened-primary-fill { fill: ${this.companyColor}; fill-opacity: 0.2;}
      .lightened-bubble-fill { fill: white; fill-opacity: 0.45;}
      .header__bot-avatar:after{ background: ${this.companyColor} !important; }
      .primary-background{ background: ${this.companyColor} !important; }
    `;
		document.getElementsByTagName('head')[0].appendChild(style);
	}

	popupButtonPressed() {
		this.chatShow = !this.chatShow;
		window.postMessage(
			{ target: 'chatbot', eventType: 'button-pressed', toggle: this.chatShow },
			'*',
		);
	}

	// Only used when chatbot is closed on smaller screens
	@HostListener('window:message', ['$event'])
	onMessage(e) {
		if (e.data.eventType === 'button-pressed-mobile' && e.data.target === 'chatbot') {
			this.chatShow = e.data.toggle;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.chatBotlogoData && changes.chatBotlogoData.currentValue) {
			this.openButtonSrc = changes.chatBotlogoData.currentValue.content;
		}
	}
}
