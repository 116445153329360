import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
	HttpHeaderResponse,
	HttpSentEvent,
	HttpProgressEvent,
	HttpResponse,
	HttpUserEvent,
	HttpEvent,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, take } from 'rxjs/operators';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { Router } from '@angular/router';
import { AuthService } from '../public/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private router: Router,
		private authService: AuthService,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.authService.getToken() && !this.isExcludedRoute(request.url)) {
			request = this.addToken(request, this.authService.getToken());
		}

		return next.handle(request).pipe(
			catchError((error) => {
				if (
					error instanceof HttpErrorResponse &&
					error.status === 401 &&
					!request.url.includes('auth') // Do not handle 401 errors on auth calls
				) {
					return this.handle401Error(request, next);
				} else {
					return throwError(error);
				}
			}),
		);
	}

	private addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	private isExcludedRoute(url: string): boolean {
		const excludedRoutes = ['/auth/verify-email'];
		return excludedRoutes.some((route) => url.includes(route));
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.authService.refreshToken().pipe(
				switchMap((response: any) => {
					this.isRefreshing = false;
					this.authService.setToken(response.data.access_token);
					this.refreshTokenSubject.next(response.data.access_token);
					return next.handle(this.addToken(request, response.data.access_token));
				}),
				catchError((error) => {
					this.isRefreshing = false;
					this.authService.logout();
					this.router.navigate(['public/sign-in']);
					return throwError(error);
				}),
			);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((access_token) => {
					return next.handle(this.addToken(request, access_token));
				}),
			);
		}
	}
}
