import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TrainChatBotService {
	constructor(private http: HttpClient) {}

	public startTraining(data): Observable<any> {
		const headers = new HttpHeaders().set(
			'Authorization',
			`Bearer ` + localStorage.getItem('ACCESS_TOKEN'),
		);
		return this.http.post(`${environment.baseUrl}/chat-interface/train`, data, {
			headers,
		});
	}

	public crawlUrls(data): Observable<any> {
		const headers = new HttpHeaders().set(
			'Authorization',
			`Bearer ` + localStorage.getItem('ACCESS_TOKEN'),
		);
		return this.http.post(`${environment.baseUrl}/chat-interface/crawl`, data, {
			headers,
		});
	}

	public fetchChatInterface(): Observable<any> {
		return this.http.get(`${environment.baseUrl}/user/chat-interface`);
	}

	public saveChatInterface(data): Observable<any> {
		return this.http.put(`${environment.baseUrl}/chat-interface`, data);
	}
}
