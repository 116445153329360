import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { IChatInterfaceResponse, ILogoPayload } from 'src/app/shared/interfaces/chat.interface';

@Component({
	selector: 'app-chat-bot-container',
	templateUrl: './chat-bot-container.component.html',
	styleUrls: ['./chat-bot-container.component.scss'],
})
export class ChatBotContainerComponent implements OnInit {
	companyColor: any = '#0DB8EE';
	showChatbot: boolean = true;
	isMobile = window.innerWidth > 800 ? false : true;
	@Input() chatInterfaceData: IChatInterfaceResponse;
	@Input() chatBotlogo: ILogoPayload;

	constructor() {}

	ngOnInit() {
		this.svgColor();
	}

	public svgColor(): void {
		const oldStyle = document.getElementById('svgColorStyle');
		if (oldStyle) {
			oldStyle.remove();
		}

		const style = document.createElement('style');
		style.type = 'text/css';
		style.id = 'svgColorStyle';
		style.innerHTML = `
      .primary-fill { fill: ${this.companyColor} !important;}
      .primary-stroke{ stroke: ${this.companyColor} !important; }
      .lightened-primary-fill { fill:  ${this.lightenColor(
				this.companyColor,
				50,
			)} !important; fill-opacity: 0.2;}
      .lightened-bubble-fill { fill: white !important; fill-opacity: 0.45;}
      .header__bot-avatar:after{ background: ${this.companyColor} !important; }
      .primary-background{ background: ${this.companyColor} !important; }
      .chat-bot-button, .popup-close, .chat-bot-button__close {
        filter: drop-shadow(0px 0px 2px  ${this.lightenColor(this.companyColor, 50)}) !important;
      }
      .circle {background-color: ${this.companyColor} !important;
        filter: drop-shadow(0px 0px 2px  ${this.lightenColor(this.companyColor, 50)}) !important;}
    `;
		document.getElementsByTagName('head')[0].appendChild(style);
	}

	@HostListener('window:message', ['$event'])
	onMessage(e) {
		if (e.data.eventType === 'button-pressed' && e.data.target === 'chatbot') {
			this.showChatbot = e.data.toggle;
		}

		// listening to event from chat-bot component on smaller screens and parse it to popup-buttom component
		if (e.data.eventType === 'button-pressed-container' && e.data.target === 'chatbot') {
			this.showChatbot = e.data.toggle;
			window.postMessage(
				{
					target: 'chatbot',
					eventType: 'button-pressed-mobile',
					toggle: e.data.toggle,
				},
				'*',
			);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.chatInterfaceData && changes.chatInterfaceData.currentValue) {
			this.companyColor = this.chatInterfaceData.colour_scheme;
			this.svgColor();
		}
		if (changes.chatBotlogo && changes.chatBotlogo.currentValue) {
			this.chatBotlogo = { ...changes.chatBotlogo.currentValue };
		}
	}

	lightenColor(hexColor: string, increaseFactor: number): string {
		// Remove the hash at the start if it's there
		hexColor = hexColor.replace(/^#/, '');

		// Convert hex to RGB
		let r = parseInt(hexColor.substring(0, 2), 16);
		let g = parseInt(hexColor.substring(2, 4), 16);
		let b = parseInt(hexColor.substring(4, 6), 16);

		// Increase each component by the increase factor, ensuring values are within 0-255
		r = Math.min(255, r + increaseFactor);
		g = Math.min(255, g + increaseFactor);
		b = Math.min(255, b + increaseFactor);

		// Convert back to hex and return
		return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
	}
}
