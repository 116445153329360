import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root', // This ensures the service is a singleton
})
export class DataService {
	private dataStore: { [key: string]: any } = {};

	constructor() {}

	// Method to store data
	public storeData(key: string, value: any): void {
		this.dataStore[key] = value;
	}

	// Method to retrieve data
	public getData(key: string): any {
		return this.dataStore[key];
	}

	public resetData() {
		this.dataStore = {};
	}
}
