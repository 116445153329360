import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IChatInterfaceResponse } from 'src/app/shared/interfaces/chat.interface';

@Injectable({
	providedIn: 'root',
})
export class TrainingProgressService {
	timerTimeout: any;
	progressTimeout: any;

	remainingTime = 120;
	chatbotProgressInPercentage = 0;

	chatbotProgressInPercentageSubject = new Subject<number>();
	trainingCompletedSubject = new BehaviorSubject<boolean>(true);
	chatInterfaceDataSubject = new Subject<IChatInterfaceResponse>();
	remainingTimeSubject = new BehaviorSubject<number>(this.remainingTime);

	simulateProgress() {
		this.trainingCompletedSubject.next(false);
		this.chatbotProgressInPercentage = 0;
		this.remainingTime = 120;
		this.remainingTimeSubject.next(this.remainingTime);

		this.updateProgressPercentage();
		this.updateTimer();
	}

	private updateProgressPercentage() {
		const incrementProgress = () => {
			if (this.chatbotProgressInPercentage < 99) {
				this.chatbotProgressInPercentage++;
				this.chatbotProgressInPercentageSubject.next(this.chatbotProgressInPercentage);

				let minTimeoutDuration = 1000;
				let maxTimeoutDuration = 1500;
				if (this.chatbotProgressInPercentage >= 90) {
					minTimeoutDuration *= 2;
					maxTimeoutDuration *= 2;
				}

				let randomTimeoutDuration =
					Math.floor(Math.random() * (maxTimeoutDuration - minTimeoutDuration + 1)) +
					minTimeoutDuration;
				this.progressTimeout = setTimeout(incrementProgress, randomTimeoutDuration);
			}
		};
		incrementProgress();
	}

	private updateTimer() {
		const decrementTimer = () => {
			if (this.remainingTime > 0 && this.chatbotProgressInPercentage < 100) {
				const shouldDecrement = this.chatbotProgressInPercentage < 90 || Math.random() < 0.5;

				if (shouldDecrement) {
					this.remainingTime--;
				}

				this.remainingTimeSubject.next(this.remainingTime);

				if (this.chatbotProgressInPercentage >= 99 && this.remainingTime <= 1) {
					this.remainingTime += 1;
				}

				this.timerTimeout = setTimeout(decrementTimer, 1000);
			} else if (this.remainingTime <= 0) {
				this.remainingTime = 0;
				this.remainingTimeSubject.next(this.remainingTime);
			}
		};
		decrementTimer();
	}

	clearProgressTimeout(): Promise<void> {
		return new Promise<void>((resolve) => {
			clearInterval(this.progressTimeout);
			clearTimeout(this.timerTimeout);

			const incrementInterval = 10;

			const increment = () => {
				if (this.chatbotProgressInPercentage < 100) {
					this.chatbotProgressInPercentage += 1;
					this.chatbotProgressInPercentageSubject.next(this.chatbotProgressInPercentage);
					setTimeout(increment, incrementInterval);
				} else {
					resolve();
				}
			};

			increment();
		});
	}
}
