<div class="circle" id="close-button.svg" (click)="popupButtonPressed()">
	<img *ngIf="chatShow" src="assets/icons/chat/close-button.svg" class="close-button-img" />

	<img *ngIf="!chatShow" [src]="openButtonSrc" class="open-button-img" id="open-button.svg" />
</div>

<!-- <div
  class="circle"
  
  id="open-button.svg"
  (click)="popupButtonPressed()"
>
  <img src="assets/icons/chat/open-button.svg" class="open-button-img" />
</div> -->
<!-- 
<img
  *ngIf="!chatShow"
  id="custom-logo-img"
  class="chat-bot-button-open"
  src="assets/icons/chat/open-button.svg"
  (click)="popupButtonPressed()"
/> -->

<!-- <svg-icon
  *ngIf="chatShow"
  class="chat-bot-button-close"
  [stretch]="false"
  (click)="popupButtonPressed()"
  applyClass="true"
  src="assets/icons/chat/close-button.svg"
>
</svg-icon> -->
