<div id="chat-container" class="chat-container" *ngIf="showChatbot">
	<div class="header">
		<div id="rocket">
			<svg-icon src="assets/icons/chat/rocket.svg" class="header__bot-avatar" [stretch]="false">
			</svg-icon>
		</div>

		<div id="river-title">
			<h4 *ngIf="chatInterfaceData">{{ chatInterfaceData['name'] }}</h4>
		</div>

		<div class="mobile-close-button" *ngIf="isMobile">
			<div class="circle" id="close-button.svg" (click)="popupButtonPressed()">
				<img
					*ngIf="showChatbot"
					src="assets/icons/chat/close-button.svg"
					class="close-button-img"
				/>
			</div>
		</div>
	</div>
	<div class="header__delimiter"></div>
	<div id="content" class="content">
		<div id="messages" class="messages" #scrollBottom>
			<div class="agent-message-wrap">
				<pre>
          <div class="agent-message">{{ this.outreachMessage }}</div>
        </pre>
			</div>

			<div
				*ngFor="let message of messages"
				[ngClass]="{
					'agent-message-wrap': message.role === 'agent',
					'user-message-wrap': message.role === 'user'
				}"
			>
				<div
					[ngClass]="{
						'agent-message': message.role === 'agent',
						'user-message': message.role === 'user'
					}"
				>
					{{ message.message }}
				</div>
			</div>
			<div *ngIf="isTyping" class="typing">
				<span class="typing__circle typing__circle_1"></span>
				<span class="typing__circle typing__circle_2"></span>
				<span class="typing__circle typing__circle_3"></span>
			</div>
		</div>
	</div>

	<div class="footer">
		<div class="footer__delimiter"></div>
		<div class="footer__field">
			<input
				*ngIf="!isMobile"
				[(ngModel)]="userInput"
				[(placeholder)]="messagePlaceholder"
				id="sendMessage"
				autocomplete="off"
				class="footer__field_input"
				(keyup.enter)="sendMessage()"
			/>

			<input
				*ngIf="isMobile"
				[(ngModel)]="userInput"
				[(placeholder)]="messagePlaceholder"
				id="sendMessage"
				autocomplete="off"
				class="footer__field_input"
			/>
		</div>
		<div class="footer__icon icon">
			<svg-icon
				class="icon__send"
				src="assets/icons/chat/send-blue.svg"
				[stretch]="false"
				(click)="sendMessage()"
			>
			</svg-icon>
		</div>
	</div>
</div>
