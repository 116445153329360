import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class FileService {
	constructor(private http: HttpClient) {}

	public createFile(data): Observable<any> {
		return this.http.post(`${environment.baseUrl}/file/create`, data);
	}

	public updateFile(data): Observable<any> {
		return this.http.put(`${environment.baseUrl}/file/update`, data);
	}
}
