import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChatBotContainerComponent } from '../public/chat-bot-container/chat-bot-container.component';
import { ChatBotComponent } from '../public/chat-bot-container/chat-bot/chat-bot.component';
import { PopupButtonComponent } from '../public/chat-bot-container/popup-button/popup-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SocketService } from '../public/services/socket.service';
import { TrainChatBotService } from '../public/services/trainchatbot.service';
import { AuthService } from '../public/services/auth.service';
import { MatTabsModule } from '@angular/material/tabs';
import { ColorPickerModule } from 'ngx-color-picker';
import { AdaptiveFontColorDirective } from '../directives/adaptivefontcolor.directive';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { ToastrModule } from 'ngx-toastr';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FileService } from '../public/services/file.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TrainingProgressService } from '../public/services/training-progres.service';

@NgModule({
	declarations: [
		ChatBotContainerComponent,
		ChatBotComponent,
		PopupButtonComponent,
		AdaptiveFontColorDirective,
	],
	providers: [
		SocketService,
		TrainChatBotService,
		AuthService,
		FileService,
		TrainingProgressService,
	],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		AngularSvgIconModule.forRoot(),
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatProgressBarModule,
		MatTabsModule,
		ColorPickerModule,
		MatGridListModule,
		MatSelectModule,
		MatChipsModule,
		MatDialogModule,
		MatSnackBarModule,
		MatProgressBarModule,
		MatToolbarModule,
		MatMenuModule,
		ToastrModule.forRoot(),
		MatCheckboxModule,
		MatSlideToggleModule,
		MatTooltipModule,
	],
	exports: [
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		AngularSvgIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatProgressBarModule,
		MatTabsModule,
		ColorPickerModule,
		MatGridListModule,
		MatSelectModule,
		MatChipsModule,
		MatDialogModule,
		ChatBotContainerComponent,
		ChatBotComponent,
		PopupButtonComponent,
		AdaptiveFontColorDirective,
		MatSnackBarModule,
		MatToolbarModule,
		MatMenuModule,
		ToastrModule,
		ChatBotContainerComponent,
		ChatBotComponent,
		PopupButtonComponent,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatTooltipModule,
	],
})
export class SharedModule {}
