import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import {
	ILoginPayload,
	ILoginResponse,
	ISignupPayload,
	ISignupResponse,
} from 'src/app/shared/interfaces/auth.interface';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private http: HttpClient,
		private router: Router,
	) {}

	public signup(data: ISignupPayload): Observable<ISignupResponse> {
		return this.http.post<ISignupResponse>(`${environment.baseUrl}/auth/signup`, data);
	}

	public login(data: ILoginPayload): Observable<ILoginResponse> {
		return this.http.post<ILoginResponse>(`${environment.baseUrl}/auth/login`, data);
	}

	public verifyEmail(token): Observable<any> {
		const headers = new HttpHeaders().set('Authorization', `Bearer ` + token);
		return this.http.post<any>(`${environment.baseUrl}/auth/verify-email`, {}, { headers });
	}

	public sendVerifyEmail(): Observable<any> {
		const headers = new HttpHeaders().set(
			'Authorization',
			`Bearer ` + localStorage.getItem('ACCESS_TOKEN'),
		);
		return this.http.post<any>(`${environment.baseUrl}/auth/send-verify-email`, {}, { headers });
	}

	public refreshToken(): Observable<any> {
		const headers = new HttpHeaders().set(
			'Authorization',
			`Bearer ` + localStorage.getItem('REFRESH_TOKEN'),
		);
		return this.http.post<any>(`${environment.baseUrl}/auth/refresh-token`, {}, { headers });
	}

	public logout(): Observable<any> {
		this.router.navigate(['public/sign-in']);
		const headers = new HttpHeaders().set(
			'Authorization',
			`Bearer ` + localStorage.getItem('ACCESS_TOKEN'),
		);
		return this.http.post<any>(
			`${environment.baseUrl}/auth/logout`,
			{ refresh_token: localStorage.getItem('REFRESH_TOKEN') },
			{ headers },
		);
	}

	public forgetPassword(email: string): Observable<any> {
		return this.http.post<any>(`${environment.baseUrl}/auth/forget-password`, {
			email,
		});
	}

	public resetPassword(new_password: string, token: string): Observable<any> {
		const headers = new HttpHeaders().set('Authorization', `Bearer ` + token);
		return this.http.post<any>(
			`${environment.baseUrl}/auth/reset-password`,
			{ new_password },
			{ headers },
		);
	}

	public changePassword(new_password: string, current_password: string): Observable<any> {
		const headers = new HttpHeaders().set(
			'Authorization',
			`Bearer ` + localStorage.getItem('ACCESS_TOKEN'),
		);
		return this.http.post<any>(
			`${environment.baseUrl}/auth/change-password`,
			{ new_password, current_password },
			{ headers },
		);
	}

	public getUserInfo(): Observable<any> {
		return this.http.get<any>(`${environment.baseUrl}/user/info`);
	}

	public isAuthenticated(): boolean {
		const token = localStorage.getItem('ACCESS_TOKEN');
		return !!token;
	}

	public getToken() {
		return localStorage.getItem('ACCESS_TOKEN');
	}

	public setToken(access_token) {
		localStorage.setItem('ACCESS_TOKEN', access_token);
	}
}
