import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocketService {
	private socket;
	constructor() {
		this.socket = io(environment.socketUrl);
	}

	sendMessage(data) {
		data['url'] = localStorage.getItem('WEBSITE_URL');
		data['chat_interface_id'] = localStorage.getItem('chatInterfaceId');
		const historyObj = JSON.parse(localStorage.getItem('CHAT_HISTORY'));
		data['chat_history'] = historyObj[localStorage.getItem('chatInterfaceId')];
		data['session_id'] = localStorage.getItem(
			`sessionId_${localStorage.getItem('chatInterfaceId')}`,
		);
		this.socket.emit('');
		this.socket.emit('send_message', data);
	}

	receiveReply() {
		const observable = new Observable<any>((observer) => {
			this.socket.on('receive_message', (data) => {
				observer.next(data);
			});
			return () => {
				this.socket.disconnect();
			};
		});
		return observable;
	}
}
