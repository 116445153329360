import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { SocketService } from '../../services/socket.service';
import { IChatInterfaceResponse } from 'src/app/shared/interfaces/chat.interface';

@Component({
	selector: 'app-chat-bot',
	templateUrl: './chat-bot.component.html',
	styleUrls: ['./chat-bot.component.scss'],
})
export class ChatBotComponent implements OnInit {
	@ViewChild('scrollBottom') private myScrollContainer: ElementRef;
	@Input() isMobile: boolean;
	@Input() chatInterfaceData: IChatInterfaceResponse;
	messagePlaceholder: any = 'Enter a message...';
	isTyping: boolean = false;
	showChatbot: boolean = true;
	messages: any = [];
	chatInterfaceId: any;
	outreachMessage: string;

	constructor(public socket: SocketService) {}

	ngOnInit(): void {
		if ('parentIFrame' in window) {
			// call iframe resizer methods here.
		}
		this.chatInterfaceId = localStorage.getItem('chatInterfaceId');
		this.subscribeToSocketForResponses();
		this.adjustChatbotScreenResolution();
		if (JSON.parse(localStorage.getItem('CHAT_HISTORY'))) {
			let historyObj = JSON.parse(localStorage.getItem('CHAT_HISTORY'));
			if (historyObj[this.chatInterfaceId]) {
				this.messages = historyObj[this.chatInterfaceId];
				this.scrollToElement();
			}
		}
		// else {
		//   this.messages.push({
		//     message: 'What can I help you with today?',
		//     role: 'agent',
		//   });
		// }
	}

	userInput = '';

	sendMessage() {
		if (this.userInput.trim() === '') {
			return;
		}
		setTimeout(() => {
			localStorage.setItem(
				'CHAT_HISTORY',
				JSON.stringify({ [this.chatInterfaceId]: this.messages }),
			);
			this.socket.sendMessage({ message: this.userInput, role: 'user' });
			this.messages.push({ message: this.userInput, role: 'user' });
			this.userInput = '';
			this.isTyping = true;
			this.scrollToElement();
		}, 100);
	}

	scrollToElement(): void {
		if (this.showChatbot) {
			setTimeout(() => {
				this.myScrollContainer.nativeElement.scroll({
					top: this.myScrollContainer.nativeElement.scrollHeight + 1000,
					behavior: 'smooth',
				});
			}, 100);
		}
	}

	onInputChange(event: KeyboardEvent) {
		event.stopPropagation();
	}

	subscribeToSocketForResponses() {
		this.socket.receiveReply().subscribe((response) => {
			if (response.session_id) {
				localStorage.setItem(
					`sessionId_${localStorage.getItem('chatInterfaceId')}`,
					response.session_id,
				);
			}
			setTimeout(() => {
				this.isTyping = false;
				this.messages.push({
					message: response['message'],
					role: response['role'],
				});
				localStorage.setItem(
					'CHAT_HISTORY',
					JSON.stringify({ [this.chatInterfaceId]: this.messages }),
				);
				this.scrollToElement();
			}, 100);
		});
	}

	adjustChatbotScreenResolution() {
		if (this.isMobile) {
			setTimeout(() => {
				document
					.getElementById('messages')
					.setAttribute('style', 'max-height: calc(100vh - 220px) ;height:calc(100vh - 220px)');
				document
					.getElementById('content')
					.setAttribute('style', 'max-height: calc(100vh - 220px) ;height:calc(100vh - 220px)');
				document
					.getElementById('chatbot-wrapper')
					.setAttribute(
						'style',
						'right: 0%; bottom:0%; left:0%; width: -webkit-fill-available; height:auto; display: inline-table;',
					);
				document.getElementById('rocket').setAttribute('style', 'flex: 50px;');
				document.getElementById('river-title').setAttribute('style', 'flex: 20%;');
			}, 1);
		}
	}

	// Used to parse message to chatbot container for smaller screens
	popupButtonPressed() {
		setTimeout(() => {
			this.showChatbot = !this.showChatbot;
			window.postMessage(
				{
					target: 'chatbot',
					eventType: 'button-pressed-container',
					toggle: this.showChatbot,
				},
				'*',
			);
		}, 1);
	}

	@HostListener('window:message', ['$event'])
	onMessage(e) {
		if (e.data.eventType === 'button-pressed' && e.data.target === 'chatbot') {
			this.showChatbot = e.data.toggle;
			this.adjustChatbotScreenResolution();
			this.scrollToElement();
		}
	}

	setOutreachMessage(message) {
		this.outreachMessage = message;
		this.scrollToElement();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.chatInterfaceData) {
			// Handle the asynchronous data when it arrives
			const currentData = changes['chatInterfaceData'].currentValue;
			if (currentData) {
				this.setOutreachMessage(currentData.outreach_message);
				this.chatInterfaceId = currentData.id;
			}
		}
	}
}
